@import "../../../styles/model-7-1/utils/variables";
.CardEvodoun_container {
    height: 100%;
    width: 100%;
}

.CardEvodoun_container:hover {
    box-shadow: -2px 10px 10px 0px rgba(0, 0, 0, 0.34);
}

.CardEvodoun_container:hover .CardEvodoun_container_head_fake {
    transform: scale(1);
    opacity: 1;
    background-color: #000000aa;
    height: 100%;
}

.CardEvodoun_container:hover .CardEvodoun_container_head_fake_2 {
    opacity: 1;
    transform: scale(1);
}

.CardEvodoun_container {
    background-color: #ffffff;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.14);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    padding: 0px;
}

.CardEvodoun_container_head a {
    display: block;
    padding: 0px;
    width: 100%;
    /* border: 1px solid red; */
}

.CardEvodoun_container_head a img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top;
}

.CardEvodoun_container_head {
    transform: translate3d(0, 0, 0);
    width: 100%;
    .tarifs {
        // border: 1px solid red;
        width: 100%;
        height: 100%;
        overflow: auto;
        color: #ffffff;
        padding: 10px;
        h3 {
            color: #ffffff;
        }
        ul {
            text-align: left;
            width: 100%;
            li {
                list-style: none;
                margin-bottom: 10px;
                // border: 1px solid red;
                strong {
                    color: $color_4;
                }
            }
        }
    }
}

.CardEvodoun_container_head_fake,
.CardEvodoun_container_head_fake_2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    transform: scale(1);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 0, 0);
    background-color: #000000aa;
}

.CardEvodoun_container_head_fake_2 {
    height: 100% !important;
    transform: scale(0);
    transition-delay: 200ms;
}

.CardEvodoun_container_head_fake_2_circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
}

.CardEvodoun_container_info_flag img {
    height: 20px;
}

.CardEvodoun_container_icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $color_2;
    border: 2px solid #fff;
    margin: 0 auto;
    transform: translate3d(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -25px;
    margin-bottom: 15px;
}


/* .CardEvodoun_container_icon span::after {
    content: attr(data-content);
    display: inline-block;
    position: absolute;
    color: #ffffff;
    left: 0;
    top: 20%;
    margin: auto;
    width: 100%;
    height: 100%;
} */

.CardEvodoun_container_icon span {
    width: 45px;
    height: 45px;
    position: absolute;
    color: #ffffff;
    text-align: center;
    font-size: 200%;
    padding: 5px;
}

.CardEvodoun_container_info_name {
    font-size: 1.2em;
    margin-top: 12px;
    padding: 0px 20px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    text-align: left;
}

.CardEvodoun_container_info_name a {
    color: rgba(0, 0, 0, 0.8);
}

.CardEvodoun_container_info_profession {
    color: $color_2;
    text-align: left;
    padding-left: 20px;
    margin-bottom: 20px;
}

.CardEvodoun_container_foot {
    flex: 1;
    border: 0px solid red;
    display: flex;
    align-items: flex-end;
    padding: 40px;
    transform: translate3d(0, 0, 0);
}

.CardEvodoun_container_foot {
    a,
    button {
        background: $color_2 !important;
        border: 1px solid $color_2 !important;
        color: #fff !important;
        padding: 9px 12px;
        cursor: pointer;
        box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-radius: 0.25rem;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        user-select: none;
        transform: translate3d(0, 0, 0);
    }
}

.CardEvodoun_container_foot button:hover {
    transform: scale(1.2);
}

.CardEvodoun_container_foot button:hover .CardEvodoun_container_foot_fake {
    width: 100%;
}

.CardEvodoun_container_foot_fake {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #00000022;
}