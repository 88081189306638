@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.search_result {
    min-height: 100vh;
    padding: 20px;
    text-align: left;
    margin-top: 10px;
    h3 {
        text-align: left;
        font-size: 1.2em;
        color: #000000;
        font-weight: bold;
    }
    &_descb {
        text-align: left;
        color: #1b5579;
    }
}

.searchView_content {
    background-color: rgb(239, 239, 239);
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.doctorSummary {
    display: list-item;
    border: 0px solid #c9d9e8;
    border-radius: 5px;
    display: flex;
    margin-top: 0px;
    max-width: 350px;
    min-height: 400px;
    margin: 10px;
    width: 100%;
}

.search_result_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    &_specialiteList {
        width: 100%;
        &_content {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    &_specialiteTitle {
        text-align: left !important;
        color: $color_2 !important;
        width: 100% !important;
        display: block;
        // border: 1px solid red;
    }
}