@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.messages {
    background-color: #ffffff;
    text-align: left;
    &_content {
        display: flex;
        width: 100%;
        margin-top: -60px;
        &_chatList {
            border: 0px solid red;
            width: 100%;
            max-width: 300px;
            position: sticky;
            height: 100vh;
            padding: 10px;
            padding-top: 70px;
            overflow-y: auto;
            background-color: rgb(244, 244, 247);
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background-color: #00000022;
                border-radius: 0px;
                position: fixed;
                left: 0;
            }
            &::-webkit-scrollbar-track {
                width: 0px;
                background-color: #00000022;
                border: 0px solid transparent;
            }
            &::-webkit-scrollbar-thumb {
                width: 10px;
                border-radius: 30px;
                background-color: #00000033;
                border: 0px solid transparent;
            }
        }
        &_right {
            display: flex;
            position: sticky;
            height: 100vh;
            width: 100%;
            padding: 10px;
            flex-direction: column;
            overflow-y: auto;
            &_messageList {
                flex: 8;
                overflow-y: auto;
                position: sticky;
                padding-top: 70px;
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                    background-color: #00000022;
                    border-radius: 0px;
                    position: fixed;
                    left: 0;
                }
                &::-webkit-scrollbar-track {
                    width: 0px;
                    background-color: #00000022;
                    border: 0px solid transparent;
                }
                &::-webkit-scrollbar-thumb {
                    width: 10px;
                    border-radius: 30px;
                    background-color: #00000033;
                    border: 0px solid transparent;
                }
            }
            &_input {
                width: 100%;
                margin-left: -10px;
                padding-right: 10px;
                padding-left: 10px;
                box-shadow: -5px -1px 5px 0px #00000011;
                // border: 1px solid red;
                &_preview {
                    height: 200px;
                    overflow-x: auto;
                    display: flex;
                    justify-content: center;
                    padding-top: 10px;
                    img {
                        width: 200px;
                        object-fit: contain;
                        border-radius: 5px;
                        border: 1px solid #00000011;
                        margin-right: 10px;
                    }
                }
                &_previewClose {
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;
                    button {
                        border: 1px solid #00000022;
                        background-color: #ffffff;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                }
                &_file {
                    padding-top: 10px;
                    &_elt {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        svg {
                            width: 30px;
                            height: 30px;
                            color: $color_2;
                            box-shadow: 5px 2px 10px 1px #00000022;
                            border-radius: 5px;
                        }
                        input {
                            width: 30px;
                            height: 30px;
                            margin-left: -30px;
                            opacity: 0;
                            cursor: pointer;
                        }
                    }
                }
                // margin-top: 0px;
            }
        }
    }
}

.chat-list,
.message-list {
    img {
        object-fit: cover !important;
    }
}

.rce-mbox-photo--img {
    img {
        object-fit: contain !important;
        max-width: 100% !important;
        width: auto !important;
    }
}