@import "../../../styles/model-7-1/utils/variables";
div.Card {
    transform: translate3d(0, 0, 0);
    min-height: 300px;
    width: 100%;
    height: 100%;
    /* box-shadow: 5px 5px 10px 3px #00000044; */
    box-shadow: 1px 1px 5px 2px #00000011;
    border-radius: 5px;
    overflow: hidden;
    user-select: none;
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

div.Card:hover {
    transform: scale(1.03);
    z-index: 3;
    box-shadow: -2px 10px 10px 0px rgba(0, 0, 0, 0.34);
    // margin-top: -10px;
}

div.Card_imageContainer {
    max-height: 300px;
    min-height: 100px;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
}

div.Card_imageContainer-bottom {
    max-width: 150px;
    // margin-top: -50px;
    margin: auto;
    margin-top: -2px;
    border-bottom: 2px solid $color_1;
}

img.Card_image {
    // width: 200px;
    // height: 80px;
    max-height: 200px;
    // min-height: 200px;
    object-fit: contain;
    object-position: center;
    // border: 1px solid red;
}

div.Card_odd {
    background-color: #00000000;
    position: absolute;
    right: 0;
    bottom: 0;
    text-align: right;
    padding: 10px;
    padding-bottom: 8px;
    -webkit-box-direction: normal;
    box-direction: normal;
    line-height: 1.7em;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    border: 0px solid red;
    /* justify-content: flex-end; */
}

div.odd {
    width: 20%;
    border: 0px solid red;
    display: inline-block;
    padding: 0px 2px;
}

div.odd:hover {
    transform: scale(1.2);
}

img.odd_ {
    width: 100%;
    object-fit: cover;
    /* margin: 0px 5px; */
}

div.Card_competence {
    background-color: #00000088;
    position: absolute;
    top: 0;
    left: 0;
    max-height: 200px !important;
    width: 100%;
    padding: 0px;
    color: #ffffff;
    overflow-y: auto;
    width: 100%;
    height: 0%;
    transform: scale(1);
    opacity: 0;
    transition-duration: 700ms;
}

div.Card_competence::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #5dc26900;
    border: 0px solid #5dc26900;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

div.Card_competence::-webkit-scrollbar-track {
    width: 5px;
    background-color: #5dc26900;
    border: 0px solid transparent;
}

div.Card_competence::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 30px;
    background-color: var(--primary-color);
    border: 0px solid transparent;
}

div.competence {
    padding: 5px 0px;
    line-height: 1.2em;
    margin: auto;
}

div.competence span {
    padding: 5px 20px;
    display: inline-block;
    border-radius: 0px;
    font-size: 0.7em !important;
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 5px;
    margin-left: 5px;
    font-weight: bold;
    border: 1px solid #ffffff;
}

div.competence span:hover {
    transform: translate3d(10px, 0px, 0px);
    font-weight: bold;
}

.link {
    color: #ffffff;
}

.Card_icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $color_3;
    border: 2px solid #fff;
    font-weight: bold;
    margin: 0 auto;
    transform: translate3d(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    position: relative;
    z-index: 10;
    transform: scale(1);
    opacity: 0;
    transition-delay: 200ms;
    transition-duration: 500ms;
    padding-top: 5px;
}

.card_icon_logo {
    color: #ffffff;
    font-size: 2em;
}

.Card_icon span {
    width: 30px;
    height: 30px;
    position: absolute;
    color: #ffffff;
    text-align: center;
    font-size: 200%;
    font-size: 1.7em;
}

div.Card_body {
    padding: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    flex: 8;
}

div.Card_body:hover div.Card_competence {
    transform: scale(1);
    opacity: 1;
    height: 100%;
}

div.Card_body:hover div.Card_icon {
    transform: scale(1);
    opacity: 1 !important;
    animation: rotation 30s infinite;
}

div.Card_body_title {
    color: $color_4;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1em;
    text-align: center;
    line-height: 1.5em;
    // font-family: 'Sofia-pro-black';
    text-decoration: none !important;
}

div.Card_body_date {
    text-align: right;
}

div.card_separator_line {
    width: 100%;
    height: 1px;
    background-color: var(--primary-color);
    margin: 10px 0px;
}

div.Card_body_text {
    width: 100%;
    letter-spacing: 0px;
    font-size: 1em;
    color: #00000077;
    // max-height: 220px;
    // min-height: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    // border: 0px solid red;
    transform: translate3d(0, 0, 0);
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    letter-spacing: 0px;
    white-space: wrap;
    line-height: 1.5;
}

div.Card_body_text::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(#ffffff55, #ffffff);
}

div.Card_body_text p {
    overflow: hidden;
    text-overflow: ellipsis;
}


/* div.Card_body_text::after {
    content: "...";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    background-color: #ffffff;
} */

.Card_body_text_hover {
    position: absolute;
    background-color: #ffffff;
    bottom: 0 !important;
    left: 0;
    max-height: 200px !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    overflow-y: auto !important;
    margin-bottom: 80px;
    padding: 20px;
    opacity: 0;
    z-index: -1000000;
}

div.Card_footer {
    padding: 0px 20px 20px 20px;
    font-size: 0.8em;
    color: var(--primary-color);
    font-weight: bold;
    text-align: right;
}

div.Card_footer span {
    font-weight: bold;
    text-align: right;
}


/* More style */

.card {
    width: 100%;
    min-height: 100%;
    background-color: #ffffff;
    box-shadow: 5px 5px 10px 3px #00000011;
    border-radius: 5px;
    transform: translate3d(0, 0, 0);
    position: relative;
    z-index: 1;
}

.card:hover {
    transform: scale(1.1);
    z-index: 10;
    box-shadow: 1px 10px 10px 3px #00000055;
}

.card-imageContainer {
    width: 100%;
    cursor: pointer;
}

.card-imageContainer img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.card-labelContainer {
    font-size: 0.7em;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    word-break: break-word;
    word-wrap: break-word;
    border-bottom: 1px solid #00000011;
    padding-bottom: 10px;
    padding-top: 5px;
    color: #000000aa;
}

.card-dateContainer {
    margin-top: 10px;
}

.card-descriptionContainer {
    font-size: 0.7em;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 0px;
}

.card-descriptionContainer p {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    letter-spacing: 0px;
    white-space: wrap;
    text-align: center;
}

.card-bottom {
    position: absolute;
    height: 45px;
    border: 0px solid red;
    bottom: 0;
    width: 100%;
}

.card-bottom- {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-bottom-content {
    border: 0px solid blue;
    flex: 1;
    height: 45px;
    max-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 1px;
}

.card-bottom-content button {
    height: 30px;
    width: 100%;
    max-width: 35px;
    background-color: #ffffff;
    border: 1px solid #00000022;
    box-shadow: 2px 2px 5px 1px #00000011;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00000077;
    cursor: pointer;
}

.card-bottom-content button:hover {
    transform: scale(1.1);
}

.card-bottom-content button:hover svg {
    color: #769ea7 !important;
}

.card-bottom-content-plus {
    color: #000000dd !important;
}

.card-bottom-content button svg {
    width: 20px;
    height: 20px;
}

#element_to_copy {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: scale(0);
    left: -1000000;
    top: -1000000000;
}

.card-shareOptions {
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    z-index: 1000;
    margin-bottom: 0px;
    opacity: 0;
    visibility: hidden;
    transform-origin: bottom;
    padding: 10px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-shareOptions-content {
    width: 100%;
    /* box-shadow: 0px 7px 10px 0px #00000022; */
    padding: 10px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
}

.card-shareOptions-content-one {
    margin-left: 2px;
    margin-right: 2px;
}

.card-shareOptions-content-rect {
    width: 20px;
    height: 20px;
    box-shadow: 5px 5px 5px 0px #00000022;
    background-color: #ffffff;
    transform: rotate(45deg);
    position: relative;
    z-index: 2;
    margin-top: -10px;
}

.card-bottom-content-copy {
    cursor: copy !important;
}

.Card_foot {
    flex: 1;
    // border: 0px solid red;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding: 0px 0px 20px 0px;
    transform: translate3d(0, 0, 0);
}

.Card_foot a {
    // background: $color_2 !important;
    border: 1px solid $color_2 !important;
    color: $color_2 !important;
    padding: 9px 12px;
    cursor: pointer;
    box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    transform: translate3d(0, 0, 0);
}

.Card_foot a:hover {
    transform: scale(1.2);
}

.Card_foot a:hover .Card_foot_fake {
    width: 100%;
}

.Card_foot_fake {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    background-color: #00000022;
}

.percent {
    font-size: 0.9em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-top: 10px;
    text-align: center;
    display: block;
    // border: 1px solid red;
    width: 100% !important;
    // height: 100% !important;
    position: relative !important;
    top: 0;
    left: 0;
}