@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.profile {
    padding: 20px;
    background-color: rgb(244, 243, 243);
    min-height: 100vh;
    &_content {
        max-width: 900px;
        width: 100%;
        margin: auto;
        .form {
            margin-bottom: 20px;
            display: block;
            border-radius: 5px;
            background-color: #ffffff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            input {
                font-size: 1em;
            }
        }
        &_section {
            &_ {
                h2 {
                    line-height: 1.5;
                    color: #61788e;
                    padding: 16px;
                    font-size: 1.5em;
                    font-weight: bold;
                    svg {
                        margin-left: 5px;
                        font-size: 1.2em;
                    }
                }
                ul {
                    li {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        border-top: 1px solid #dae0e7;
                        padding: 8px 16px;
                        min-height: 48px;
                        width: 100%;
                        &:hover {
                            background-color: #f6fcfe;
                            // background: linear-gradient(0deg, #e5f5fb, #f6fcfe);
                            border-radius: 5px;
                        }
                    }
                }
                &list {
                    &_left {
                        display: flex;
                        svg {
                            font-size: 1.8em;
                            color: $color_1;
                        }
                        span {
                            margin-left: 10px;
                            font-size: 1.2em;
                            display: block;
                            font-weight: 500;
                            color: #000000aa;
                        }
                        label {
                            display: block;
                            margin-top: 1px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}