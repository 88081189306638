@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.appointment {
    padding: 20px;
    background-color: rgb(244, 243, 243);
    min-height: 100vh;
    &_content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        .card-sth {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 20px;
            max-width: 300px;
            width: 100%;
        }
    }
}