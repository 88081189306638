@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.login {
    background-color: #f5f4f4;
    width: 100%;
    min-height: 100vh;
    padding-top: 70px;
    padding-bottom: 100px;
    text-align: left;
    font-size: 1.1em;
    &_content {
        background-color: #ffffff;
        width: 100%;
        max-width: 500px;
        margin: auto;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        &_head {
            color: #374767;
            background-color: #f4f9f3;
            border-color: #dedada;
            position: relative;
            padding: 10px;
            padding-top: 15px;
            padding-left: 20px;
            padding-bottom: 15px;
            border-radius: 5px 5px 0px 0px;
            display: flex;
            align-items: center;
            &_back {
                background-color: #f4f9f3;
                button {
                    background: transparent;
                    border: 0;
                    color: #374767;
                    font-size: 1.8em;
                }
            }
            &_logo {
                font-size: 3.5em;
                color: $color_4;
            }
            &_title {
                padding-left: 20px;
                color: #374767;
                &_top {
                    font-size: 1.7em;
                }
                &_bottom {
                    margin-top: 5px;
                    span {
                        font-size: 0.9em;
                    }
                    img {
                        width: 50px;
                        margin-bottom: -2px;
                    }
                }
            }
        }
        &_form {
            padding: 20px;
            &_input {
                margin-bottom: 20px;
                &_title {
                    font-weight: 600;
                    color: #374767;
                    letter-spacing: 1px;
                }
                &_balise {
                    input,
                    select {
                        width: 100%;
                        margin-top: 5px;
                        padding: 8px 10px;
                        line-height: 1.42857143;
                        color: #555;
                        background-color: #fff;
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                        outline: none;
                        &:focus {
                            box-shadow: 0 0px 2px 1px #a5ccec90;
                        }
                    }
                    input[type="radio"] {
                        width: auto;
                        margin-top: 5px;
                        color: #555;
                        margin-bottom: 10px;
                        margin-right: 10px;
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                        outline: none;
                        &:focus {
                            box-shadow: 0 0px 2px 1px #a5ccec90;
                        }
                    }
                    label {
                        color: #000000aa;
                    }
                }
                &_subText {
                    font-size: 0.7em;
                    color: #555;
                    margin-top: 7px;
                }
                &_verf {
                    font-size: 0.9em;
                    color: #555;
                    margin-top: 7px;
                    display: flex;
                    flex-direction: column;
                    span {
                        margin-bottom: 10px;
                    }
                }
            }
            &_button {
                button {
                    background-color: $color_4;
                    padding: 7px 10px;
                    border-width: 0px;
                    color: #ffffff;
                    font-weight: bold;
                    font-size: 0.9em;
                    border-radius: 2px;
                    cursor: pointer;
                    width: 100%;
                    display: block;
                    border-radius: 5px;
                    margin-bottom: 10px;
                }
                a {
                    color: $color_2;
                    padding: 7px 10px;
                    border-width: 0px;
                    font-weight: bold;
                    font-size: 0.9em;
                    border-radius: 2px;
                    letter-spacing: 1px;
                }
            }
        }
    }
}

.returnHome {
    margin-top: 20px;
    text-align: center;
    a {
        color: $color_4;
        font-size: 1.2em;
    }
}