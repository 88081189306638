@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.homeViewContent {
    &_section1 {
        // min-height: 100vh;
        height: auto;
        border: 0px solid red;
        &_prtfl {
            height: auto;
            min-height: 100%;
            img {
                height: 100vh;
                object-position: top;
            }
            &_presentation {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                min-height: 100%;
                z-index: 10;
                background-color: #00000088;
                padding: 20px;
                padding-top: 40px;
                margin-top: 70px;
                h3 {
                    border: 0px solid red;
                    text-align: center;
                    color: #ffffff;
                    font-weight: bold;
                    font-size: clamp(1.5em, calc(10vmin), 4em);
                    word-wrap: break-word;
                }
                .homeViewContent_content_title {
                    h4 {
                        color: #ffffff;
                        margin-top: 30px;
                        font-weight: bold;
                        .changer_pre {
                            a {
                                border-bottom: 1px solid $color_2;
                                color: $color_2;
                            }
                        }
                    }
                }
            }
        }
    }
    &_content_search {
        justify-content: center;
        display: flex;
        align-items: center;
        max-width: 900px;
        border: 0px solid red;
        margin: auto;
        @media (max-width: 550px) {
            flex-direction: column;
            &_input {
                width: 100%;
                background-color: #ffffff;
                margin-bottom: 10px;
                input {
                    border-radius: 10px;
                }
            }
            .name {
                border-radius: 10px 10px 10px 10px !important;
                position: relative;
                z-index: 2;
                // border: 1px solid red;
            }
            .location {
                border-radius: 10px 10px 10px 10px !important;
                position: relative;
                z-index: 1;
            }
            &_button {
                width: 100%;
                button {
                    width: 100%;
                    border-radius: 10px;
                    border-radius: 10px 10px 10px 10px !important;
                }
            }
        }
        // flex-wrap: wrap;
        .name {
            border-radius: 5px 0px 0px 5px;
        }
        .location {
            border-radius: 0px 5px 5px 0px;
        }
        &_input {
            width: 100%;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            svg {
                margin-left: 10px;
                margin-right: 10px;
            }
            input {
                // padding: 12px 15px;
                border: 0;
                outline: none;
                font-size: 1.2em;
                width: 100%;
            }
            &_balise {
                // padding: 12px 15px;
                border-radius: 0px !important;
                border: 0;
                outline: none;
                font-size: 1.2em;
                width: 100%;
                position: relative;
                margin: 0 !important;
                z-index: 1000;
                border-color: transparent;
            }
        }
        &_button {
            button {
                background-color: $color_2;
                padding: 9px 15px;
                border: 0;
                font-size: 1.2em;
                border-radius: 0px 5px 5px 0px;
                color: #ffffff;
                height: 52px;
            }
        }
    }
}

.section2 {
    // min-height: 100vh;
    padding: 20px;
    padding-top: 80px;
    background-color: #fdfdfd;
    &_icon {
        img {
            width: 150px;
        }
    }
    &_title {
        text-align: center;
    }
    &_subtitle {
        text-align: center;
        margin-top: 10px;
        h3 {
            font-family: Arial, Helvetica, sans-serif;
            color: $color_2;
            font-size: 1.7em;
            line-height: 1.5;
            font-weight: bold;
            span {
                width: 20px;
                height: 20px;
                display: inline-block;
                border: 1px solid #00000022;
                margin-right: 10px;
                // background-color: $color_2;
            }
        }
    }
    &_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 40px;
        &_elt {
            max-width: 350px;
            margin: 10px;
        }
    }
    &_paragraph {
        p {
            max-width: 900px;
            margin: auto;
            padding-top: 40px;
            font-size: 1.2em;
            word-wrap: break-word;
            text-align: justify;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            -o-hyphens: auto;
            hyphens: auto;
            overflow-wrap: break-word;
        }
    }
    &_steps {
        margin-top: 130px;
        max-width: 100%;
        // border: 1px solid red;
        &_line {
            display: block;
            margin-bottom: 0px;
            width: 100%;
            // border: 1px solid green;
        }
        &_list {
            margin-top: -140px;
            display: flex;
            justify-content: space-between;
            // border: 1px solid red;
            .steps_ll {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            .step1 {
                animation: 7s bounceAnimation infinite;
            }
            .step2 {
                animation: 7s bounceAnimation infinite;
                animation-delay: 200ms;
            }
            .step3 {
                animation: 7s bounceAnimation infinite;
                animation-delay: 300ms;
            }
            .step4 {
                animation: 7s bounceAnimation infinite;
                animation-delay: 400ms;
            }
            .two {
                transform: translateY(-100px);
            }
            // .steps {
            //     border: 1px soli red;
            // }
        }
        @media (max-width: 1000px) {
            &_list {
                margin-top: -100px;
            }
            .steps_count {
                width: 120px;
                height: 120px;
            }
        }
        @media (max-width: 790px) {
            &_list {
                flex-wrap: wrap;
            }
            .steps {
                width: 40%;
                // border: 1px solid red;
            }
            .steps_count {
                width: 150px;
                height: 150px;
            }
        }
        @media (max-width: 600px) {
            &_list {
                .two {
                    transform: translateY(0px);
                }
            }
            .steps {
                width: 100%;
                margin-bottom: 30px;
                // border: 1px solid red;
            }
            .steps_count {
                width: 150px;
                height: 150px;
            }
        }
    }
    &_form {
        max-width: 900px;
        margin: auto;
        margin-top: 60px;
        &_ {
            margin-top: -120px !important;
        }
    }
}