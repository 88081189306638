@import "../model-7-1/utils/variables";
@import "../model-7-1/utils/functions";
@import "../model-7-1/utils/mixins";
@import "../model-7-1/utils/placeholders";
html {
    scroll-behavior: smooth;
    min-height: 100%;
}

*::before,
*::after {
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
    transition: all 250ms;
    box-sizing: border-box;
}

.js-plotly-plot {
    transition-duration: 0ms;
}

.js-plotly-plot * {
    transition-duration: 0ms;
}

input,
button {
    user-select: auto;
}

body {
    font-size: 1em;
    scroll-behavior: smooth;
}

body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: $color_4;
    border: 0px solid $color_4;
    border-radius: 0px;
    position: fixed;
    left: 0;
}

body::-webkit-scrollbar-track {
    width: 0px;
    background-color: $color_4;
    border: 0px solid transparent;
}

body::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 30px;
    background-color: $color_2;
    border: 0px solid transparent;
}

.textForm {
    word-wrap: break-word;
    text-align: justify;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    -o-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    letter-spacing: 0px;
    white-space: wrap;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffffffaa;
    z-index: 100000000000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 150px;
        height: 150px;
        object-fit: contain;
        border-radius: 100%;
    }
    .loader2 {
        width: 500px;
        height: 500px;
        z-index: 2;
        position: absolute;
        top: center;
    }
}

.backLoader {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 200px;
        border-radius: 100%;
    }
    &_cov {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #ffffffaa;
    }
}

.title-container-default {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 1em;
    display: inline-block;
    border-radius: 17.5px;
    border-bottom-left-radius: 0px;
    box-shadow: 0px 10px 30px 0px rgba(51, 57, 178, 0.25);
    padding: 20px 30px;
    background-image: linear-gradient(90deg, #ffbc45 0%, #ff5959 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 40px;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

.card-sth {
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-top: 5px solid $color_2;
    border-radius: 0px 0px 0px 0px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    text-align: left;
    word-break: break-all;
    &-title {
        color: #000000aa;
        letter-spacing: 0.5px;
        h3 {
            font-size: 1.1em !important;
        }
    }
    &-list {
        margin-top: 20px;
        &-content {
            &-title {
                font-weight: bold;
                font-size: 1.1em;
                letter-spacing: 0.5px;
            }
            .wait {
                color: $color_2;
            }
            &-body {
                margin-top: 0px;
                margin-bottom: 40px;
            }
        }
        &-elt {
            &-container {
                display: flex;
                align-items: center;
                border-left: 1px solid #00000033;
                &-left {
                    width: 15px;
                    height: 2px;
                    background-color: #00000022;
                }
            }
            border: 1px solid #00000011;
            border-radius: 5px;
            padding: 5px 10px;
            margin-top: 10px;
            width: 100%;
            &-description {
                width: 100%;
                flex: 1;
                // border: 1px solid red;
                margin-top: 5px;
                color: #000000aa;
            }
            &-dateTime {
                text-align: right;
            }
            &-button {
                display: flex;
                justify-content: flex-end;
                margin-top: 5px;
                button {
                    // padding: 6px 15px;
                    margin-left: 10px;
                    border-radius: 30px;
                    border-width: 0px;
                    font-weight: bold;
                    font-size: 0.9em;
                    cursor: pointer;
                    background-color: transparent;
                }
                .valid {
                    // background-color: rgb(123, 213, 123);
                    color: rgb(123, 213, 123);
                }
                .cancel {
                    // background-color: rgb(248, 70, 70);
                    color: rgb(248, 70, 70);
                }
            }
        }
    }
}

.sth-valid {
    border-top-color: rgb(123, 213, 123);
}

.sth-cancel {
    border-top-color: rgb(248, 70, 70);
}

.table-classic {
    min-width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: #ffffff;
    overflow: auto;
    caption {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 1.1em;
        color: $color_2;
        font-weight: 500;
        letter-spacing: 1px;
    }
    thead {
        tr {
            th {
                padding: 10px;
                border: 1px solid #00000022;
                color: #27344e;
                font-weight: 500;
                font-size: 1.1em;
            }
        }
    }
    tbody {
        tr {
            td {
                padding: 10px;
                border: 1px solid #00000022;
                color: #374767;
                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 5px;
                }
                button {
                    padding: 5px 10px;
                    margin-left: 5px;
                    margin-right: 5px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                .view {
                    background-color: #50bc50;
                    border-width: 0px;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .update {
                    background-color: rgb(250, 167, 122);
                    border-width: 0px;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                }
                .delete {
                    background-color: rgb(241, 40, 40);
                    border-width: 0px;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }
}

.information {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000000000;
    backdrop-filter: blur(8px);
    background-color: #0000ff22;
    overflow-y: auto;
    .white {
        background-color: #ffffff !important;
    }
    &_content {
        background-color: #ffede0aa;
        max-width: 800px;
        width: 100%;
        margin: auto;
        margin-top: 30px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 1px 1px 5px 2px #00000033;
        &_close {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            button {
                background-color: transparent;
                border-width: 0px;
                font-size: 1.5em;
                color: red;
                cursor: pointer;
            }
        }
        &_banner {
            margin: auto;
            margin-top: 10px;
            max-width: 600px;
            &_content {
                background-color: #e4f7fe;
                border: 1px solid #b5e6f6;
                padding: 10px 20px;
                border-radius: 5px;
                color: #024c9b;
                margin-bottom: 20px;
            }
        }
        &_body {
            ul {
                li {
                    button {
                        background-color: transparent;
                        border: 0;
                    }
                    .delete {
                        background-color: red;
                        color: #ffff;
                        width: 25px;
                        height: 25px;
                        margin-left: 10px;
                        border-radius: 100%;
                        font-size: 0.9em;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .flex {
        display: flex;
        align-items: center;
        width: 100%;
        .form_input {
            width: 100%;
            input {
                width: 100%;
            }
        }
        button {
            background-color: $color_4;
            color: #ffffff;
            border: 0;
            padding: 10px 15px;
            margin-left: 10px;
            border-radius: 5px;
            font-size: 0.9em;
            margin-bottom: 5px;
        }
    }
}

.form {
    padding: 20px;
    text-align: left;
    width: 100%;
    .flex {
        display: flex;
        width: 100%;
        @media (max-width: 700px) {
            flex-direction: column;
        }
        .form_input {
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .datePickerContent {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        @media (max-width: 1000px) {
            flex-direction: column;
        }
        &_ {
            width: 100%;
            border: 1px solid #00000011;
            border-radius: 5px;
            padding: 20px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    &_input {
        margin-bottom: 10px;
        &_title {
            font-weight: 600;
            color: #374767;
            letter-spacing: 1px;
        }
        &_balise {
            input,
            select {
                width: 100%;
                margin-top: 5px;
                padding: 8px 10px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
                &:focus {
                    box-shadow: 0 0px 2px 1px #a5ccec90;
                }
            }
            textarea {
                width: 100%;
                height: 200px;
                resize: none;
                margin-top: 5px;
                padding: 8px 10px;
                line-height: 1.42857143;
                color: #555;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
            }
            input[type="radio"] {
                width: auto !important;
                margin-top: 5px;
                color: #555;
                margin-bottom: 10px;
                margin-right: 10px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                outline: none;
                &:focus {
                    box-shadow: 0 0px 2px 1px #a5ccec90;
                }
            }
            label {
                color: #000000aa;
            }
        }
        &_subText {
            font-size: 0.7em;
            color: #555;
            margin-top: 7px;
        }
    }
    &_button {
        button {
            background-color: $color_4;
            padding: 7px 10px;
            border-width: 0px;
            color: #ffffff;
            font-weight: bold;
            font-size: 0.9em;
            border-radius: 2px;
            cursor: pointer;
        }
        a {
            color: $color_2;
            padding: 7px 10px;
            border-width: 0px;
            font-weight: bold;
            font-size: 0.9em;
            border-radius: 2px;
            letter-spacing: 1px;
        }
    }
}

.card-payment {
    a {
        color: #666;
    }
    h1 {
        font-weight: bold;
        margin: 0 0 10px;
    }
    h4 {
        margin-bottom: 30px;
    }
    h1,
    h2,
    h4 {
        text-align: center;
    }
    .App-payment {
        padding: 30px;
    }
    form {
        margin: 30px auto 0;
        max-width: 400px;
    }
    .form-actions {
        margin-top: 15px;
    }
    .App-cards {
        margin: 0 auto;
        max-width: 1280px;
        text-align: center;
    }
    .App-cards-list {
        display: flex;
        margin-top: 30px;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .App-cards-list>* {
        transform: scale(0.8);
        margin-bottom: 30px !important;
    }
    .App-highlight {
        font-family: serif;
        margin: 15px auto 0;
        max-width: 300px;
    }
    .App-highlight>div {
        padding-left: 40px;
    }
    .App-badges {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .App-badges a {
        display: block;
    }
    .App-badges a+a {
        margin-top: 18px;
    }
    .github__btn {
        align-items: center;
        background-color: #ccc;
        border-radius: 4px;
        color: #444;
        display: flex;
        line-height: 1;
        padding: 8px 12px;
    }
    .github__btn:hover {
        color: #444;
        text-decoration: none;
    }
    .github__btn img {
        display: inline-block;
        height: 28px;
        margin-right: 8px;
    }
    .App-credits {
        background-color: #000;
        color: #fff;
        line-height: 50px;
        text-align: center;
    }
    .App-credits a {
        color: #fff;
        font-weight: bold;
    }
    @media screen and (min-width: 600px) {
        .App-badges {
            flex-direction: row;
        }
        .App-badges a+a {
            margin-top: 0;
            margin-left: 18px;
        }
    }
}

.globalALert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: 10px;
    padding: 0px 10px;
    z-index: 100000000000000000000;
    transform: translate3d(0, -1000%, 0);
    animation: animFromTop 700ms forwards;
    &_container {
        position: relative;
        z-index: 100000000000000000000;
    }
    &_content {
        width: 100%;
        z-index: 1000;
        max-width: 500px;
        margin: auto;
        &_loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
    &_back {
        background-color: #ffffff55;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000000;
        backdrop-filter: blur(8px);
    }
}

.table_content {
    max-width: 800px;
    margin: auto;
    table {
        width: 100%;
        color: #474849;
        text-align: center;
        border: 1px solid #c0c1c3;
        border-radius: 0.5rem;
        overflow: hidden;
        border-collapse: unset;
        border-spacing: 0;
        background-color: #fff;
        thead {
            color: #464748;
            background-color: #c8d8fc;
            border-collapse: unset;
            tr {
                display: table-row;
                vertical-align: inherit;
                border-color: inherit;
                border-collapse: unset;
                background-color: #c8d8fc;
                border-top-left-radius: 0.5rem;
                th {
                    overflow: hidden;
                    background-color: #c8d8fc;
                    border-collapse: unset;
                }
            }
        }
        tbody {
            display: table-row-group;
            vertical-align: middle;
            border-color: inherit;
            tr {
                width: 100%;
                color: #474849;
                text-align: center;
                border: 1px solid #c0c1c3;
                border-radius: 0.5rem;
                overflow: hidden;
                border-collapse: unset;
                border-spacing: 0;
                td,
                th {
                    padding: 15px 0px;
                    // border: 0.5px solid #c0c1c344;
                }
            }
        }
    }
}

.tab-button-1 {
    background-color: transparent;
    color: $color_2;
    border-width: 0px;
}

.main-nav__dashboard {
    color: #ffffff;
    margin-left: 10px;
    svg {
        font-size: 1.2em;
    }
    span {
        font-size: 1em;
        margin-left: 5px;
    }
}

.active {
    font-weight: bold !important;
    color: #ffffff !important;
    /* Ajoutez d'autres styles selon vos besoins */
}

.inactive {
    color: #ffffff88 !important;
}

.activeDashboard {
    background-color: $color_4;
}

#_ROOT_ {
    // position: absolute;
    // width: 100%;
    // height: 100%;
}

#__ROOT__ {
    padding-top: 0px;
    position: relative;
    z-index: 10000000000;
    background-color: #ffffff;
}

.steps_s {
    width: 100%;
    display: flex;
    .step_s {
        width: 100%;
        height: 4px;
        background-color: #00000022;
        border-radius: 5px;
    }
    .valid {
        background-color: $color_2;
    }
}

.button-style-1 {
    // background-color: $color_3;
    color: $color_3;
    border: 1px solid $color_3;
    // color: #000000;
    padding: 15px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 0px;
    letter-spacing: 0px;
    display: inline-block;
    &:hover {
        background-color: $color_3;
        color: #000000aa;
    }
}

.button-style-3 {
    // background-color: #ffffff;
    color: #ffffff;
    border: 1px solid #ffffff;
    // color: #000000;
    padding: 15px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 0px;
    letter-spacing: 0px;
    display: inline-block;
    &:hover {
        background-color: #ffffff;
        color: #000000aa;
    }
}

.button-style-2 {
    // background-color: $color_3;
    // border: 1px solid $color_3;
    color: #ffffff;
    padding: 15px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 0px;
    letter-spacing: 0px;
    display: inline-block;
}

ul,
li {
    list-style: none;
}

.hoverStyle1 {
    &::before {
        background-color: $color_2;
        border-top-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0;
        left: auto;
        width: 0%;
        right: 0;
        position: absolute;
        height: 5px;
        bottom: -15px;
        background: $color_2;
        content: "";
        display: block;
        transition: all 0.3s linear;
        box-sizing: borer-box;
        @media (max-width: 920px) {
            background: #ffffff;
            bottom: -5px;
        }
    }
    &::after {
        background-color: $color_2;
        position: absolute;
        height: 5px;
        width: 0%;
        bottom: -15px;
        left: 0;
        right: auto;
        content: "";
        display: block;
        border-top-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 5px;
        transition: all 0.3s linear;
        box-sizing: border-box;
        @media (max-width: 920px) {
            background: #ffffff;
            bottom: -5px;
        }
    }
    &-container {
        transform: translate3d(0, 0, 0);
        &:hover .hoverStyle1::before {
            width: 50%;
            left: 50%;
        }
        &:hover .hoverStyle1::after {
            width: 50%;
            left: auto;
            right: 50%;
        }
    }
}

.currentMenuBaseLine {
    background-color: $color_4;
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: -15px;
    border-radius: 5px;
}

.position-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0;
    z-index: 10000000;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.position-sticky {
    position: sticky;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.kando-header {
    // top: 0;
    // left: 0;
    // padding: 0px 10px;
    // border-style: solid;
    // border-width: 0px 0px 1px 0px;
    // border-color: rgba(255, 255, 255, 0.2);
    // // box-shadow: 2px 2px 5px 0px rgba(41.999999999999986, 67.00000000000004, 113, 0.15);
    // transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    // z-index: 10;
    background-color: $color_4;
    box-shadow: 1px 1px 5px 1px #00000022;
    height: 70px;
    // backdrop-filter: blur(8px);
    // position: relative;
    padding: 20px !important;
    @media (max-width: 920px) {
        padding: 10px !important;
    }
    &-pre {
        display: flex;
    }
    &-icon {
        width: 250px;
    }
    &-nav {
        &_ {
            margin-left: 50px;
        }
        &-menu {
            &-item {
                margin-right: 20px;
                &-link {
                    text-decoration: none;
                    font-weight: 500;
                    font-size: 1em;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
}

.grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    border-collapse: collapse;
    transform: scale(1);
    z-index: 1;
    tr {
        td {
            border: 2px dotted #ffffff;
        }
    }
}

.title-style-1 {
    // font-size: 1.3em;
    font-size: clamp(1.5em, calc(10vmin), 4em);
    display: inline-block;
    background-color: #ffffff;
    color: $color_4;
    // border: 1px solid #00000022;
    // border-radius: 50px 0px 50px 0px;
    // box-shadow: 0px 0px 5px 1px #00000011;
    padding: 15px 20px;
    font-weight: bold;
    // width: 100%;
    // max-width: 400px;
}

.steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;
    margin: 10px;
    &_count {
        width: 150px;
        height: 150px;
        background-color: #050505;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 50%;
        font-size: 35px;
        color: #fff;
        font-weight: 600;
        background-color: #fff;
        background-image: linear-gradient(180deg, #8e55ee 0%, rgb(142, 85, 238, .14) 100%);
        &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: #8e55ee;
            transform: rotate(45deg);
            border-radius: 77.5px 77px 0px 77.5px;
            transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -webkit-transition: all 500ms ease;
            -ms-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            box-shadow: 0px 30px 50px 0px rgba(248, 199, 94, 0.2);
            z-index: 0;
        }
        span {
            position: relative;
            z-index: 1;
        }
    }
    .yellow {
        &::before {
            background-image: linear-gradient(180deg, rgb(248, 199, 94) 0%, rgba(248, 199, 94, 0.14902) 100%);
            background-color: #f8c75e;
        }
    }
    .green {
        &::before {
            background-image: linear-gradient(180deg, rgb(80, 225, 182) 0%, rgba(80, 225, 182, 0.14902) 100%);
            background-color: #50e1b6;
        }
    }
    .red {
        &::before {
            background-image: linear-gradient(180deg, rgb(249, 105, 105) 0%, rgba(249, 105, 105, 0.14902) 100%);
            background-color: #f96969;
        }
    }
    h3 {
        margin: 0;
        font-size: clamp(1em, calc(4vmin), 1.4em);
        line-height: 1.2;
        font-weight: 500;
        color: #00000099;
        margin-top: 45px;
        a {
            color: inherit;
        }
    }
}

.footer-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-row-content {
    margin-left: 10px;
}

.site-footer__bottom_ {
    .container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        a {
            margin-bottom: 20px;
        }
    }
}

.about_lnN {
    @media(max-width: 580px) {
        width: 100% !important;
        // border: 1px solid red;
        .my-auto {
            width: 100% !important;
            // border: 1px solid red;
            .service-two__main {
                width: 100% !important;
                .section2_icon {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 40px;
                    display: none;
                }
                // border: 1px solid red;
                h3,
                p {
                    // border: 1px solid red;
                    max-width: 100vw !important;
                    width: 100vw !important;
                    display: block;
                    margin-left: -10px;
                }
            }
        }
    }
}