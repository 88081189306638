@import "../../../styles/model-7-1/utils/variables";
@media (max-width: 850px) {
    .kando-section {
        flex-direction: column !important;
        margin-bottom: 20px;
    }
    .kando-section-left {
        width: 100%;
        padding: 0px 21px;
        padding-right: 0px;
    }
    .kando-section-right {
        width: 100%;
        padding: 20px 20px;
        margin-top: 40px;
        margin-left: -30px;
    }
}

@media (min-width: 920px) {
    .kando-header-nav_ {
        position: relative !important;
        transform: scale(1) !important;
        visibility: visible !important;
        opacity: 1 !important;
        z-index: 10000 !important;
    }
    .kando-header-menuIcon {
        position: absolute;
        right: 0;
        z-index: -1000;
        opacity: 0;
        visibility: hidden;
        transform: scale(0);
    }
    .kando-header-nav-menu {
        transform: translate(0%, 0) !important;
    }
    .kando-header-nav-menu-item-button {
        position: absolute;
        transform: scale(0);
        visibility: hidden;
        opacity: 0;
        z-index: -10000;
    }
    .kando-header-nav-menu-cross {
        position: absolute;
        transform: scale(0);
        visibility: hidden;
        opacity: 0;
        z-index: -10000;
    }
    .mainMenu {
        li:hover {
            .submenu {
                transform: scaleY(1);
            }
        }
    }
    .submenu {
        transform-origin: 0 0;
        transform: scaleY(0);
        overflow: hidden;
        position: absolute;
        width: 200px;
        background-color: $color_1;
        padding: 10px;
        border-radius: 5px;
        a {
            color: #ffffff;
        }
        li {
            padding: 15px 10px !important;
            border-bottom: 1px solid #ffffff11;
            &:hover {
                a {
                    color: $color_3;
                }
            }
        }
    }
}

@media (max-width: 920px) {
    /* .kando-header-menuIcon {
        position: absolute;
        right: 0;
        z-index: -1000;
        opacity: 0;
        visibility: hidden;
        transform: scale(0);
    } */
    .mainMenu {
        flex-direction: column !important;
    }
    .kando-header-icon {
        width: 100px;
    }
    .kando-header-slug {
        font-size: 0.7em;
    }
    .currentMenuBaseLine {
        background-color: #ffffff !important;
        position: absolute;
        height: 5px;
        width: 100%;
        bottom: -5px !important;
        border-radius: 5px;
        margin-left: 0px;
    }
    // .kando-header-nav {}
    .kando-header-nav-menu {
        position: fixed;
        right: 0;
        top: 0;
        width: 90%;
        max-width: 300px;
        background-color: $color_4;
        height: 100% !important;
        color: #ffffff !important;
        z-index: 100000000000;
    }
    .menu-back {
        position: fixed;
        z-index: 10000;
        background-color: #00000055;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .kando-header-nav-menu {
        flex-direction: column !important;
        align-items: flex-start !important;
        padding: 20px;
        padding-left: 30px;
    }
    .kando-header-nav-menu-item {
        margin-bottom: 20px;
        border-bottom: 1px solid #ffffff22;
        width: 100%;
        padding: 10px 5px;
    }
    .kando-header-nav-menu-item-link {
        color: #ffffff !important;
        font-size: 0.9em;
    }
    .kando-header-nav_ {
        position: absolute !important;
        transform: scale(0) !important;
        visibility: hidden !important;
        opacity: 0 !important;
        z-index: -10000 !important;
    }
    .kando-header-nav-menu-item-button {
        position: relative;
        transform: scale(1);
        visibility: visible;
        opacity: 1;
        z-index: 10000;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .kando-header-nav-button-w {
        padding: 10px 20px !important;
        font-size: 0.9em !important;
        background-color: #ffffff !important;
        color: $color_1 !important;
        box-shadow: 0px 0px 0px 0px #88c7e0aa !important;
    }
    .kando-header-nav-button-w-span::before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: all 0.5s;
        opacity: 1;
        transform: translate(-105%, 0);
        background-color: $color_2;
        z-index: 10000;
    }
    .kando-header-nav-button-w:hover {
        box-shadow: 0px 0px 0px 0px #88c7e0aa;
    }
    .kando-header-nav-button-w:hover .kando-header-nav-button-wn::before {
        transform: translate(0%, 0);
    }
    .kando-header-nav-menu-cross {
        position: absolute;
        transform: scale(1);
        visibility: visible;
        opacity: 1;
        z-index: 10000;
        left: 0;
        top: 0;
        margin-top: 5px;
        margin-left: 5px;
    }
}