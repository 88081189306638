@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.prescription {
    padding: 20px;
    background-color: rgb(244, 243, 243);
    min-height: 100vh;
    &_list {
        &_head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #00000022;
            border-radius: 5px 5px 0px 0px;
            margin-bottom: 5px;
            padding: 10px 20px;
            flex-wrap: wrap;
            &_left {
                margin-bottom: 10px;
                margin-right: 10px;
                &_content {
                    display: flex;
                    align-items: center;
                    span {
                        color: #000000aa;
                    }
                    select {
                        margin-left: 5px;
                        border: 1px solid #00000022;
                        background-color: #ffffff;
                        padding: 5px;
                        border-radius: 5px;
                        outline: none;
                        option {
                            padding: 5px;
                        }
                    }
                    svg {
                        margin-left: 5px;
                        color: $color_2;
                        font-size: 1.3em;
                    }
                }
            }
            &_right {
                margin-bottom: 10px;
                &_content {
                    display: flex;
                    align-items: center;
                    input {
                        padding: 10px 15px;
                        padding-right: 25px;
                        background-color: #ffffff;
                        border-radius: 5px;
                        border: 1px solid #00000022;
                        outline: none;
                        color: $color_2;
                        font-weight: bold;
                        font-size: 0.8em;
                        letter-spacing: 0.5px;
                        width: 100%;
                    }
                    svg {
                        margin-left: -25px;
                        color: $color_2;
                    }
                }
            }
        }
        background-color: #ffffff00;
        &_title {
            background-color: #ffffff;
            text-align: left;
            padding: 20px;
            border: 1px solid #00000022;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            span {
                color: #000000aa;
                font-size: 1.2em;
            }
            a {
                color: $color_2;
            }
            &_export {
                display: flex;
                flex-wrap: wrap;
                button {
                    margin-left: 2px;
                    margin-right: 2px;
                    padding: 5px 10px;
                    background-color: #ffffff;
                    border: 1px solid #00000022;
                    border-radius: 3px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    span {
                        color: $color_1;
                        font-size: 1.1em;
                    }
                    svg {
                        font-size: 1.3em;
                        margin-left: 2px;
                        color: #000000aa;
                    }
                }
            }
        }
        &_content {
            margin-top: 0px;
            padding: 15px;
            border: 1px solid #00000022;
            background-color: #ffffff;
            border-top-width: 0px;
            overflow: auto;
        }
    }
    .information_content {
        &_head {
            margin-top: 10px;
            background-color: #ffffff;
            padding: 20px;
            border-radius: 5px 5px 0px 0px;
            display: flex;
            justify-content: space-between;
            &_date {
                color: #000000aa;
            }
            &_history {
                a {
                    color: $color_2;
                }
            }
        }
        &_body {
            text-align: left;
            background-color: #ffffff;
            margin-top: 5px;
            padding: 20px;
            border-radius: 0px 0px 5px 5px;
            &_head {
                display: flex;
                justify-content: space-between;
                &_dr {
                    font-size: 1.3em;
                    // font-weight: bold;
                    letter-spacing: 0.5px;
                    color: #000000aa;
                }
                &_hospital {
                    &_name {
                        font-weight: bold;
                        letter-spacing: 0.7px;
                        font-size: 1.3em;
                        color: $color_1;
                        margin-bottom: 5px;
                    }
                    &_address {
                        color: #000000aa;
                    }
                }
            }
            &_patient {
                margin-top: 30px;
                b {
                    letter-spacing: 0.7px;
                }
                b.patient_name {
                    color: $color_1;
                }
            }
            &_cc {
                margin-top: 30px;
                &_title {
                    font-size: 1.3em;
                }
                ul {
                    margin-top: 15px;
                    li {
                        padding-left: 20px;
                        margin-bottom: 10px;
                    }
                }
                table {
                    margin-top: 15px;
                }
            }
        }
        &_foot {
            text-align: right;
            margin-top: 20px;
            span {
                display: inline;
                width: auto;
                text-align: right;
            }
            &_signature {
                margin-right: 10px;
            }
        }
    }
}