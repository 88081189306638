@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.payment {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        iframe {
            border-radius: 5px;
            background: #ffffff !important;
            body {
                background: #ffffff !important;
                .modal {
                    background: #ffffff !important;
                }
                .d-block {
                    background: #ffffff !important;
                }
            }
        }
    }
}