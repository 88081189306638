@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.agenda {
    padding: 20px;
    background-color: rgb(226, 226, 226);
    &_content {
        display: flex;
        @media (max-width: 1200px) {
            flex-direction: column;
        }
        &_addPlan {
            width: 100%;
            max-width: 400px;
            min-height: 400px;
            max-height: 100vh;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            background-color: #ffffff;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            &_drag {
                flex: 1;
                height: 100%;
                margin-bottom: 20px;
                overflow: auto;
                padding: 2px;
                &_content {
                    text-align: left;
                    padding: 10px;
                    border: 1px solid #00000011;
                    margin-bottom: 2px;
                    &_description {
                        font-weight: bold;
                        color: $color_2;
                        letter-spacing: 0.5px;
                        font-size: 1em;
                        margin-bottom: 5px;
                        margin-top: 5px;
                        text-transform: uppercase;
                    }
                    &_start {
                        color: #2f9616;
                    }
                    &_end {
                        // color: #f53333;
                    }
                }
            }
            &_button {
                padding-bottom: 20px;
                button {
                    padding: 15px 20px;
                    background-color: $color_2;
                    border: 1px solid #ffffff00;
                    font-weight: bold;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
        &_viewPlan {
            @media (max-width: 1200px) {
                margin-left: 0px;
                margin-top: 20px;
            }
            margin-left: 20px;
            width: 100%;
            height: auto;
            background-color: #ffffff;
            padding: 20px;
            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
        }
    }
}

.eventTitle {
    padding: 5px;
    text-wrap: wrap;
    cursor: pointer;
}