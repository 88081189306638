@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.request {
    padding: 20px;
    background-color: rgb(244, 243, 243);
    min-height: 100vh;
    &_content {
        max-width: 900px;
        width: 100%;
        margin: auto;
        .form {
            margin-bottom: 20px;
            display: block;
            border-radius: 5px;
            background-color: #ffffff;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            input {
                font-size: 1em;
            }
        }
    }
}