// $color_1: #30b7b8;
// $color_s1: #30b7b8aa;
// $color_1_: #30b7b811;
// $color_2: #f78222;
// $color_3: #a74a18;
// $color_2_: #f7822255;
// :export {
//     color_1: #30b7b8;
//     color_2: #f78222;
// }
$color_1: #30b7b8;
$color_s1: #30b7b8aa;
$color_1_: #30b7b811;
$color_2: #f78222;
$color_3: #a74a18;
$color_4: #162f62;
$color_2_: #f7822255;
:export {
    color_1: #30b7b8;
    color_2: #f78222;
}