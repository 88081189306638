@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
header {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100000;
}

.nav {
    text-align: left;
}

.top_nav {
    background-color: $color_s1;
    backdrop-filter: blur(8px);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 390px) {
        display: block;
        &_connection {
            margin-top: 20px;
        }
    }
    ul {
        display: flex;
        align-items: center;
        @media (max-width: 390px) {
            justify-content: space-between;
        }
        li {
            margin-left: 5px;
            margin-right: 5px;
            a.home {
                color: #ffffff;
                font-size: 1.7em;
                font-weight: 500;
                text-decoration: none;
                span {
                    transition: all 250ms;
                }
                span:hover {
                    color: $color_3;
                    transform: scaleY(1.3);
                    display: inline-block;
                }
            }
            a.connection {
                font-size: 0.8em;
                font-weight: bold;
                letter-spacing: 0.5px;
                display: inline-block;
            }
            a.search {
                font-size: 1.4em;
            }
        }
    }
}

.bottom_nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px !important;
}

footer.footer {
    padding: 20px;
    color: #ffffffaa;
    font-size: 0.8em;
    background-color: #000000dd;
    padding-top: 50px;
}

.footer li::before {
    content: "";
}

.footer ul {
    padding: 0px;
    margin: 0px;
    text-align: left;
}

.footer li {
    margin: 0px;
    padding: 0px;
}

div.footer_text a {
    color: #ffffffaa;
    border-bottom: 1px solid #ffffffaa;
    padding-bottom: 1px;
}

.site-footer__upper .container .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.site-footer__upper .container .row .col-lg-4 {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.footer-widget .footer-widget__title {
    font-size: 1.5em;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
    text-align: left;
}

.footer-widget__social a {
    color: #ffffff;
    margin-right: 10px;
    cursor: pointer;
    font-size: 2em;
    padding: 5px;
}

.footer-widget__social a:hover {
    /* background-color: #ffffff; */
    color: $color_3 !important;
    transform: scale(1.1);
}

.footer-widget__social a i {
    cursor: pointer;
}

.footer-widget ul li {
    margin: 10px 0px;
}

.footer-widget ul li a {
    color: #fff;
    font-size: 1.2em;
    letter-spacing: 0px;
    font-weight: normal;
    cursor: pointer;
    line-height: 1.6;
}

.footer-widget ul li a span {
    margin-right: 5px;
}

.footer-contacts p {
    margin: 10px 0px;
    font-size: 1.2em;
    letter-spacing: 0px;
    font-weight: normal;
    color: #fff;
    line-height: 1.6;
    text-align: left;
}

.footer-contacts p i {
    margin-right: 5px;
}

.footer-contacts p a {
    color: #5dc269;
}

.footer-widget__social img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: block;
    margin-bottom: 15px;
}

.footer-widget__social .nav_icon {
    margin-bottom: 10px;
}

.footer-widget__social .nav_icon_text {
    font-size: 0.7em !important;
}

.nav_icon_text_name {
    ul {
        display: flex;
        align-items: center;
        li {
            margin-left: 5px;
            margin-right: 5px;
            a.home {
                color: #ffffff;
                font-size: 1.7em;
                font-weight: 500;
                text-decoration: none;
                margin: 0;
                span {
                    transition: all 250ms;
                    margin: 0;
                    text-decoration: none;
                }
                span:hover {
                    color: $color_3;
                    transform: scaleY(1.3);
                    display: inline-block;
                }
            }
            a.connection {
                font-size: 0.8em;
                font-weight: bold;
                letter-spacing: 0.5px;
            }
            a.search {
                font-size: 1.4em;
            }
        }
    }
}