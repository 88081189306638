@import "./model-7-1/utils/variables";
@import "./model-7-1/utils/functions";
@import "./model-7-1/utils/mixins";
@import "./model-7-1/utils/placeholders";
.pricingList {
    &_content {
        &_ {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            &elt {
                background-color: #fff;
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 20px;
                border-radius: 8px;
                padding: 20px;
                width: 100%;
                max-width: 200px;
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-shadow: 0 4px 11px rgba(1, 53, 134, .08), 0 0 2px rgba(1, 53, 134, .07);
                h3 {
                    font-size: 1.5em;
                }
                span {
                    -webkit-text-fill-color: transparent;
                    background: -webkit-linear-gradient(225deg, $color_1, #107aca);
                    -webkit-background-clip: text;
                    background-clip: text;
                    display: block;
                    font-size: 2em;
                    font-weight: bold;
                }
                a {
                    color: #ffffff;
                    background-color: $color_2;
                    padding: 5px 15px;
                    border-width: 0px;
                    font-weight: bold;
                    font-size: 1.1em;
                    border-radius: 30px;
                    letter-spacing: 1px;
                    margin-top: 10px;
                }
            }
        }
    }
}