@import "../../../styles/model-7-1/utils/variables";
.kando-header-nav-menu-item {
    border-left: 1px solid #00000011;
    padding-left: 15px;
}

.kando-header-nav-menu-item-link {
    color: #ffffff;
}

.mainMenu {
    display: flex;
}